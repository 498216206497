.collection-cards-main-wrapper {
  width: 100%;
}

.SetData-Btn {
  display: flex;
  // margin-top: 15px;
  justify-content: flex-end;
  align-items: center;
  // width: 738px;
  height: 50px;
  padding: 10px 0px 10px 0px;
  gap: 30px;

  position: fixed;
  right: 60px;
  bottom: 0;
  margin-bottom: 0;
  width: 100%;
  background-color: #fff;
  @media screen and (max-width: 1200px){
    right: 20px;

  }
  @media screen and (max-width: 767px){
    right: 10px;

  }
  @media screen and (max-width: 576px) {
    justify-content: center;

  }

  .active {
    background-color: blue;
    color: white;
  }

  button {
    border: none !important;
    color: #303030;
    background-color: white !important;
    cursor: pointer;
    font-size: 22px;
    letter-spacing: 0.0178571429em;
    font-weight: 500;
    padding-inline: 0;
    display: flex;
    img, svg{
      color: #000000;
      filter: brightness(0) saturate(100%) invert(0%) sepia(90%) saturate(7405%) hue-rotate(198deg) brightness(128%) contrast(101%);
    }
    @media screen and (max-width: 576px) {
      font-size: 16px;
      width: 20px;

    }
  }

  .arrow-btn-wrapper{
    display: flex;
    gap: 14px;
  }
}

.span-14 {
  // border-radius: 4px;
  // width: 65px;
  font-size: 14px;
  font-weight: 400;
  gap: 4px;
  align-items: center;
  font-style: normal;
  line-height: 20px;
  background-color: #fff;
  display: flex;
  padding: 0px 0;
  @media screen and (max-width: 576px) {
    font-size: 10px;

  }
}
.span-15{
  // style={{marginTop:'15px', fontSize:'14px' , fontWeight:'400' , marginLeft:'25px' , marginRight:'20px'}}
  font-size: 14px;
  font-weight: 400;
  gap: 4px;
  font-style: normal;
  line-height: 20px;
  background-color: #fff;
  display: flex;
  margin: 0;
  @media screen and (max-width: 576px) {
    font-size: 12px;
    margin: 0px 20px 0px;

  }
}

.div-39 {
  color: var(--Primary-text, #2d2f31);
  /* text-align: center; */
  // padding: 6px 8px 6px 8px;
  // flex-grow: 1;
  font-size: 14px;
  font-weight: 600;
  // width: 65px;
  border: none;
  // height: 40px;
  line-height: 20px;
  gap: 4px;
  white-space: nowrap;
  // border: 1px solid lightgray;
  background-color: #fff;
  // border-radius: 4px;
  // font: 400 14px/143% Poppins, sans-serif;
  // border: none;
  cursor: pointer;
  @media screen and (max-width: 576px) {
    font-size: 12px;

  }
}

@media (max-width: 991px) {
  .div-39 {
    white-space: initial;
  }
}
